<template>
  <form class="form form-light">
    <UtilSteps @update:step="$emit('update:step', $event)" :step="step" :steps="['Amount', 'Business', 'Farm', 'Personal']" class="mb-4" />

    <div v-show="step === 1">
      <div class="form-group mb-6">
        <label class="form-label">Finance Amount</label>
        <div class="form-field form-field-xl">
          <money v-model="application.borrowing.amount" v-bind="money"></money>
        </div>
      </div>

      <div class="form-group mb-6">
        <label class="form-label">Finance Term</label>
        <div class="form-field form-field-xl">
          <select v-model="application.borrowing.term">
            <option value="">Please Select</option>
            <option v-for="(option, i) in options.financeTerms" :value="option.value" :key="`finance-term-${i}`">{{ option.text }}</option>
          </select>
        </div>
      </div>
    </div>

    <div v-show="step === 2">
      <div class="form-group mb-6">
        <label class="form-label">Farm/Business Trading Name</label>
        <div class="form-field">
          <input v-model="application.business.name" />
        </div>
      </div>

      <div class="form-group mb-6">
        <label class="form-label">Farm/Business Legal Type</label>
        <div class="form-field">
          <select v-model="application.business.legalType">
            <option value="">Please Select</option>
            <option v-for="(option, i) in options.businessLegalTypes" :value="option.value" :key="`business-legal-type-${i}`">
              {{ option.text }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group mb-6">
        <label class="form-label">Farm/Business Trading History</label>
        <div class="form-field">
          <select v-model="application.business.tradingYears">
            <option value="">Please Select</option>
            <option v-for="(option, i) in options.businessTradingYears" :value="option.value" :key="`business-trading-years-${i}`">
              {{ option.text }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group mb-6">
        <label class="form-label">VAT Number</label>
        <div class="form-field">
          <span>
            <span class="flag-icon flag-icon-gb"></span>
            GB
          </span>
          <input v-mask="'### ### ###'" v-model="application.business.taxId" placeholder="e.g. 111 222 333" />
        </div>
      </div>
    </div>

    <div v-show="step === 3">
      <div class="form-group mb-6">
        <label class="form-label">
          <FontAwesomeIcon :icon="icons.faWheat" />
          Farm Land Breakdown
        </label>
        <table class="form-table table-fixed mt-2">
          <tbody>
            <tr>
              <th>Owned</th>
              <td class="form-table-fixed-cell"><input v-model="application.land.owned" placeholder="0" /></td>
              <td class="form-table-fixed-cell">
                <select>
                  <option>Acres</option>
                  <option>Hectares</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>Rented</th>
              <td class="form-table-fixed-cell"><input v-model="application.land.rented" placeholder="0" /></td>
              <td class="form-table-fixed-cell">
                <select>
                  <option>Acres</option>
                  <option>Hectares</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>Contract Farmed</th>
              <td class="form-table-fixed-cell"><input v-model="application.land.contracted" placeholder="0" /></td>
              <td class="form-table-fixed-cell">
                <select>
                  <option>Acres</option>
                  <option>Hectares</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="form-group mb-6">
        <label class="form-label">
          <FontAwesomeIcon :icon="icons.faCow" />
          Farm Activity Breakdown
        </label>
        <table class="form-table table-fixed mt-2">
          <tbody>
            <tr>
              <th>Store Cattle</th>
              <td class="form-table-fixed-cell"><input v-model="application.farming.storeCattle" placeholder="0" size="8" /></td>
              <td class="form-table-fixed-cell"><input value="Head" readonly /></td>
            </tr>
            <tr>
              <th>Breeding Cattle</th>
              <td class="form-table-fixed-cell"><input v-model="application.farming.breedingCattle" placeholder="0" size="8" /></td>
              <td class="form-table-fixed-cell"><input value="Head" readonly /></td>
            </tr>
            <tr>
              <th>Dairy Cattle</th>
              <td class="form-table-fixed-cell"><input v-model="application.farming.dairyCattle" placeholder="0" size="8" /></td>
              <td class="form-table-fixed-cell"><input value="Head" readonly /></td>
            </tr>
            <tr>
              <th>Sheep</th>
              <td class="form-table-fixed-cell"><input v-model="application.farming.sheep" placeholder="0" size="8" /></td>
              <td class="form-table-fixed-cell"><input value="Head" readonly /></td>
            </tr>
            <tr>
              <th>Arable</th>
              <td class="form-table-fixed-cell"><input v-model="application.farming.arable" placeholder="0" size="8" /></td>
              <td class="form-table-fixed-cell">
                <select>
                  <option>Acres</option>
                  <option>Hectares</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-show="step === 4">
      <div class="form-group mb-6">
        <label class="form-label">Applicant Name</label>
        <div class="form-field">
          <input v-model="application.user.name" />
        </div>
      </div>

      <div class="form-group mb-6">
        <label class="form-label">Applicant DOB</label>
        <div class="form-field">
          <input v-model="application.user.dob" v-mask="'##-##-####'" placeholder="DD-MM-YYYY" />
        </div>
      </div>

      <div class="form-group mb-6">
        <label class="form-label">Applicant Address</label>
        <div class="form-field">
          <textarea v-model="application.user.address" />
        </div>
      </div>

      <fieldset>
        <legend>Partners</legend>
      </fieldset>
    </div>

    <!--<button @click.prevent="postApplication()">Send</button>-->
  </form>

  <!--
      - slack (x started finance)
      - amount borrowed (min £3k)
      - term
      - business
      - legal type
      - date of birth
      - years trading
      - vat number
      - partners
      - land owner or tenanted
      - land owned number
      - land tenanted number
      - land contracted number
      - dairy cattle number
      - breeding cattle number
      - store cattle number
      - sheep number
      - arable land
      - terms
      - soft credit

      Quote
      - Price.Price
      - Quantity
      - Meta.Types
      - Meta.Sexes
    -->
</template>

<script>
import { faCow, faWheat } from '@fortawesome/pro-regular-svg-icons'
import { financeTerms, businessLegalTypes, businessTradingYears } from '@/lib/options'
import UtilSteps from '@/components/utilities/UtilSteps'

export default {
  components: {
    UtilSteps
  },
  props: {
    step: {
      type: Number
    }
  },
  data() {
    return {
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '£',
        suffix: '',
        precision: 2,
        masked: false
      },
      options: {
        financeTerms,
        businessLegalTypes,
        businessTradingYears
      },
      icons: {
        faCow,
        faWheat
      },
      application: {
        user: {
          name: 'Jamie McInnes',
          address: '44 West Marden, Chichester',
          dob: '24-10-1984'
        },
        business: {
          name: 'Hectare Agritech',
          legalType: 'Partnership',
          taxId: '',
          tradingYears: '5',
          partnerDetails: []
        },
        land: {
          owned: '123',
          rented: '233',
          contracted: '123'
        },
        borrowing: {
          amount: '6000',
          term: '24'
        },
        farming: {
          breedingCattle: '34',
          storeCattle: '22',
          dairyCattle: '134',
          sheep: '12',
          arable: '155'
        }
      }
    }
  },

  computed: {
    submission() {
      return {
        questions: {
          mandatory: [
            {
              question: 'What is the name of your trading business',
              input: { value: this.application.business.name }
            },
            {
              question: 'Please confirm the legal form of your business',
              selection: { value: this.application.business.legalType }
            },
            { question: 'What is your Date of Birth', input: { value: this.application.user.dob } }
          ],
          optional: [
            {
              question: 'How many years has your farming business been trading',
              selection: { value: this.application.business.tradingYears }
            },
            { question: 'What is your VAT number', input: { value: this.application.business.taxId } },
            {
              question: 'Is your land owned or rented',
              selection: {
                value: ['Owned', 'Rented', 'Farmed Under Contract'],
                sub: {
                  input: {
                    value: [this.application.land.owned, this.application.land.rented, this.application.land.contracted]
                  }
                }
              }
            },
            {
              question: 'What type of farming do you carry out',
              selection: {
                value: ['Dairy Cattle', 'Breeding Cattle', 'Store Cattle', 'Sheep', 'Arable'],
                sub: {
                  Arable: {
                    question: 'Please indicate area below',
                    input: {
                      value: [this.application.farming.arable]
                    }
                  },
                  question: 'How many animals',
                  input: {
                    value: [
                      this.application.farming.dairyCattle,
                      this.application.farming.breedingCattle,
                      this.application.farming.storeCattle,
                      this.application.farming.sheep
                    ]
                  }
                }
              }
            }
          ],
          confirmation: { terms: true, credit: true }
        },
        applicant: {
          name: this.application.user.name,
          address: this.application.user.address
        },
        loan: { amount: this.application.borrowing.amount, term: this.application.borrowing.term, quoted: [] }
      }
    }
  },

  methods: {
    nextStep() {
      this.$emit('update:step', this.step + 1)
    },

    prevStep() {
      this.$emit('update:step', this.step - 1)
    },

    postApplication() {
      let call = this.$call({
        url: 'https://dev.api.hctr.farm/finance/v1/application',
        method: 'POST',
        request: {
          data: this.submission
        }
      })
      this.$api(call)
      //https://dev.api.hctr.farm/finance/v1/application
    },

    addPartner() {
      this.application.business.partnerDetails.push({
        name: '',
        address: '',
        dob: ''
      })
    }
  }
}

/*
let answers = {
  questions: {
    mandatory: [
      {
        question: 'What is the name of your trading business',
        input: { value: 'Hectare Agritech', combo: true, items: 'hec.company', item_key: 'name' }
      },
      {
        question: 'Please confirm the legal form of your business',
        selection: { items: ['Sole Trader', 'Partnership', 'LTD', 'PLC', 'Other'], value: 'Partnership' },
        sub: {
          exclude: ['Sole Trader'],
          question:
            'Please provide the Name, Address and Date of Birth of all business partners or confirm you do not have any business partners.',
          input: {
            textArea: true,
            label: 'Name, Address, Date of Birth',
            value: 'Mike Smith, Fenmead Brook Avenue, Warsash, 24-10-1984',
            toggle: { label: 'I confirm I have no business partners.', value: true }
          }
        }
      },
      { question: 'What is your Date of Birth', input: { mask: '##-##-####', placeholder: 'DD-MM-YYYY', value: '24101984' } }
    ],
    optional: [
      {
        question: 'How many years has your farming business been trading',
        selection: { items: ['< 1', '2', '3', '4', '5', '6', '7', '8', '9', '> 10'], value: '6' }
      },
      { question: 'What is your VAT number', input: { value: 'gb12313132' } },
      {
        question: 'Is your land owned or rented',
        selection: {
          items: ['Owned', 'Rented', 'Farmed Under Contract'],
          value: ['Rented'],
          multiple: true,
          sub: {
            question: 'Please indicate area below',
            input: { value: ['1118'], min: 1, max: 500000, type: 'Number' },
            selection: { items: ['acres', 'hectares'], value: ['acres', 'acres', 'acres'] }
          }
        }
      },
      {
        question: 'What type of farming do you carry out',
        selection: {
          items: ['Dairy Cattle', 'Breeding Cattle', 'Store Cattle', 'Sheep', 'Arable'],
          value: ['Breeding Cattle', 'Dairy Cattle'],
          multiple: true,
          sub: {
            Arable: {
              question: 'Please indicate area below',
              input: { value: [], min: 1, type: 'Number' },
              selection: { items: ['acres', 'hectares'], value: [] }
            },
            question: 'How many animals',
            input: { value: ['123', '12'], min: 1, unit: 'head', type: 'Number' }
          }
        }
      }
    ],
    confirmation: { terms: true, credit: true }
  },
  applicant: {
    name: 'Mr Jamie McInnes',
    address: 'Midhurst, GU29 0, United Kingdom, West Marden, Midhurst, West Sussex, GU29 0HY, United Kingdom'
  },
  loan: { amount: 5000, term: 36, quoted: [] }
}

let questions = {
  status: 'success',
  message: 'Questions Retreived',
  data: {
    mandatory: [
      { question: 'What is the name of your trading business', input: { value: '', combo: true, items: 'hec.company', item_key: 'name' } },
      {
        question: 'Please confirm the legal form of your business',
        selection: { items: ['Sole Trader', 'Partnership', 'LTD', 'PLC', 'Other'], value: '' },
        sub: {
          exclude: ['Sole Trader'],
          question:
            'Please provide the Name, Address and Date of Birth of all business partners or confirm you do not have any business partners.',
          input: {
            textArea: true,
            label: 'Name, Address, Date of Birth',
            value: '',
            toggle: { label: 'I confirm I have no business partners.', value: false }
          }
        }
      },
      { question: 'What is your Date of Birth', input: { mask: '##-##-####', placeholder: 'DD-MM-YYYY', value: '' } }
    ],
    optional: [
      {
        question: 'How many years has your farming business been trading',
        selection: { items: ['< 1', '2', '3', '4', '5', '6', '7', '8', '9', '> 10'], value: '' }
      },
      { question: 'What is your VAT number', input: { value: '' } },
      {
        question: 'Is your land owned or rented',
        selection: {
          items: ['Owned', 'Rented', 'Farmed Under Contract'],
          value: [],
          multiple: true,
          sub: {
            question: 'Please indicate area below',
            input: { value: [], min: 1, max: 500000, type: 'Number' },
            selection: { items: ['acres', 'hectares'], value: ['acres', 'acres', 'acres'] }
          }
        }
      },
      {
        question: 'What type of farming do you carry out',
        selection: {
          items: ['Dairy Cattle', 'Breeding Cattle', 'Store Cattle', 'Sheep', 'Arable'],
          value: [],
          multiple: true,
          sub: {
            Arable: {
              question: 'Please indicate area below',
              input: { value: [], min: 1, type: 'Number' },
              selection: { items: ['acres', 'hectares'], value: [] }
            },
            question: 'How many animals',
            input: { value: [], min: 1, unit: 'head', type: 'Number' }
          }
        }
      }
    ],
    confirmation: { terms: false, credit: false }
  },
  _links: { self: { href: '/finance/v1/quote/questions' } }
}
*/
</script>

<style lang="scss" scoped></style>
