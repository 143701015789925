<template>
  <div class="p-4">
    <FormFinance ref="finance" :step.sync="step" />

    <div class="flex mt-10">
      <div class="flex-shrink mr-2">
        <button class="btn btn-block" @click="$refs.finance.prevStep()">Back</button>
      </div>
      <div class="flex-grow ml-2">
        <button class="btn btn-block bg-blue-900 text-white" @click="$refs.finance.nextStep()">Continue</button>
      </div>
    </div>

    <div class="flex justify-between items-center mt-6">
      <img src="@/assets/img/logo-fca.png" class="img-grayscale opacity-25 block h-10 w-auto" />
      <img src="@/assets/img/logo-farmpay.png" class="img-grayscale opacity-25 block h-10 w-auto" />
    </div>
  </div>
</template>

<script>
import FormFinance from '@/components/forms/FormFinance'

export default {
  components: {
    FormFinance
  },
  data() {
    return {
      step: 1
    }
  }
}
</script>

<style lang="scss" scoped></style>
