<template>
  <div>
    <div class="steps">
      <div
        v-for="(text, i) in steps"
        @click="$emit('update:step', i + 1)"
        :class="{ 'bg-gray-300 text-gray-800': step === i + 1 }"
        :key="`step-${i}`"
      >
        {{ text }}
      </div>
    </div>

    <div class="progress">
      <div :style="{ width: `${(step / steps.length) * 100}%` }"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array
    },
    step: {}
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
