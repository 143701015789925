let i

let financeTerms = [
  { text: '6 months', value: 6 },
  { text: '12 months', value: 12 },
  { text: '18 months', value: 18 },
  { text: '24 months', value: 24 },
  { text: '30 months', value: 30 },
  { text: '36 months', value: 36 },
  { text: '42 months', value: 42 },
  { text: '48 months', value: 48 },
  { text: '54 months', value: 54 },
  { text: '60 months', value: 60 }
]

i = 1
let businessTradingYears = []
while (i <= 10) {
  let delimiter = ''
  if (i === 1) {
    delimiter = '< '
  }
  if (i === 10) {
    delimiter = '> '
  }
  businessTradingYears.push({
    text: `${delimiter}${i} years`,
    value: `${i}`
  })
  i++
}

let businessLegalTypes = [
  { text: 'Sole Trader', value: 'Sole Trader' },
  { text: 'Partnership', value: 'Partnership' },
  { text: 'LTD', value: 'LTD' },
  { text: 'PLC', value: 'PLC' },
  { text: 'Other', value: 'Other' }
]

export { financeTerms, businessTradingYears, businessLegalTypes }
